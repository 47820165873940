import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
import en from "./en";
import ru from "./ru";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: "languageOnly",
    resources: { en, ru },
    fallbackLng: "en",
    detection: {
      order: ["cookie", "navigator"],
      caches: ["cookie"],
      checkWhitelist: true,
    },
    debug: false,
    whitelist: ["en", "ru"],
    nonExplicitWhitelist: false,

    interpolation: {
      escapeValue: false,
    },

    nsSeparator: ":::",
    keySeparator: "::",
  });

export default i18n;
