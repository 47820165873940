const en = {
  translation: {
    header: {
      "How it works?": "How it works?",
      FAQ: "FAQ",
      Partners: "Partners",
      Franchise: "Franchise",
    },
    footer: {
      "Support service": "Support service",
      "User Agreement": "User Agreement",
      INN: "INN",
      "ENERGO TECHNOLOGIES LLC": "ENERGO TECHNOLOGIES LLC",
    },
    home: {
      "Rental service": "Rental service",
      "of powerbanks": "of powerbanks",
      "Charge on the go!": "Charge on the go!",
      "How it works?": "How it works?",
      Install: "Install",
      "the mobile app": "the mobile app",
      "Download on the": "Download on the",
      "Get it on": "Get it on",
      Take: "Take",
      "a powerbank": "a powerbank",
      "at the nearest terminal": "at the nearest terminal",
      "Find the terminal in the application that is next to you. Scan the QR code or enter the number terminal.":
        "Find the terminal in the application that is next to you. Scan the QR code or enter the number terminal.",
      Charge: "Charge",
      "on the go": "on the go",
      "and hand over in a convenient place":
        "and hand over in a convenient place",
      "Take the powerbank with you and return it anywhere from the app.":
        "Take the powerbank with you and return it anywhere from the app.",
      "Where are we located?": "Where are we located?",
      "More than 1000 terminals throughout Moscow":
        "More than 1000 terminals throughout Moscow",
      "Transport hubs": "Transport hubs",
      "Fitness clubs": "Fitness clubs",
      "Central markets": "Central markets",
      "Cafes and restaurants": "Cafes and restaurants",
      "Shopping malls": "Shopping malls",
      "All over the city": "All over the city",
      "Find the nearest": "Find the nearest",
      "Frequent questions": "Frequent questions",
      "How to take the battery?": "How to take the battery?",
      "Log in to the app.": "Log in to the app.",
      "Choose a convenient payment method.":
        "Choose a convenient payment method.",
      "Find the nearest terminal on the map.":
        "Find the nearest terminal on the map.",
      "Scan the QR code on the terminal or enter its number manually.":
        "Scan the QR code on the terminal or enter its number manually.",
      "After 10 seconds, remove the battery from the cell.":
        "After 10 seconds, remove the battery from the cell.",
      "How much does the rent cost?": "How much does the rent cost?",
      "1 hour – 50 rubles.": "1 hour – 50 rubles.",
      "1 day – 100 rubles.": "1 day – 100 rubles.",
      "Basic rate:": "Basic rate:",
      "50₽/first hour, then 100₽/per day": "50₽/first hour, then 100₽/per day",
      "Please note that the cost may vary in different places and establishments.":
        "Please note that the cost may vary in different places and establishments.",
      "The current rates are indicated on the terminals and in the application.":
        "The current rates are indicated on the terminals and in the application.",
      "The money is debited from your card before the start of the rental and according to the time counter.":
        "The money is debited from your card before the start of the rental and according to the time counter.",
      "How to return the battery?": "How to return the battery?",
      "Find the nearest terminal on the map in the app.":
        "Find the nearest terminal on the map in the app.",
      "Click the “return battery“ button in the app.":
        "Click the “return battery“ button in the app.",
      "Insert the battery into a free cell.":
        "Insert the battery into a free cell.",
      "Which phones can be charged?": "Which phones can be charged?",
      "Each battery has three cables:": "Each battery has three cables:",
      "How many batteries": "How many batteries",
      "can I take?": "can I take?",
      "You can take only one battery per account.":
        "You can take only one battery per account.",
      "You can take up to four powerbanks on one account.":
        "You can take up to four powerbanks on one account.",
      "Cooperation with us": "Cooperation with us",
      "Contact the commercial department by number":
        "Contact the commercial department by number",
      "On weekdays from 9:00 to 18:00": "On weekdays from 9:00 to 18:00",
      "Why do we charge for a day if the power bank charges for only 2 hours?":
        "Why do we charge for a day if the power bank charges for only 2 hours?",
      "The power bank charge is enough to fully charge the phone up to 100% in 2 hours (capacity 5000 mAh). The rates are calculated specifically so that you can safely hand over the powerbank at a convenient time and place for you.":
        "The power bank charge is enough to fully charge the phone up to 100% in 2 hours (capacity 5000 mAh). The rates are calculated specifically so that you can safely hand over the powerbank at a convenient time and place for you.",
      "What to do if": "What to do if",
      "The terminal is not working": "The terminal is not working",
      "Contact technical support by phone":
        "Contact technical support by phone",
      "The QR code is not readable": "The QR code is not readable",
      "Try entering the number manually.": "Try entering the number manually.",
      "If you are in the subway, then enter 6 digits under the QR code.":
        "If you are in the subway, then enter 6 digits under the QR code.",
      "If in an institution, then contact technical support by phone":
        "If in an institution, then contact technical support by phone",
      "The terminal has run out of batteries":
        "The terminal has run out of batteries",
      "Find the nearest terminal in the application where there are free batteries.":
        "Find the nearest terminal in the application where there are free batteries.",
      "The lease started, but the terminal did not issue the battery":
        "The lease started, but the terminal did not issue the battery",
      "Check the cell again – you may not have seen the issued battery. If nothing has happened, contact technical support by phone":
        "Check the cell again – you may not have seen the issued battery. If nothing has happened, contact technical support by phone",
      "The rented battery does not work (the indicator does not light, the cable is torn off)":
        "The rented battery does not work (the indicator does not light, the cable is torn off)",
      "Lost powerbank / damaged": "Lost powerbank / damaged",
      "There is no access to the phone number and you need to hand over the battery?":
        "There is no access to the phone number and you need to hand over the battery?",
      "If you rent a powerbank to an institution, then just insert it into the terminal.":
        "If you rent a powerbank to an institution, then just insert it into the terminal.",
      "If in the subway, then contact technical support by phone":
        "If in the subway, then contact technical support by phone",
      "Any other questions?": "Any other questions?",
      "Write to us! We at EnerGO are all charged up!":
        "Write to us! We at EnerGO are all charged up!",
      "Ready to be always": "Ready to be always",
      "in touch?": "in touch?",
      "Download the mobile app": "Download the mobile app",
      "from the App Store or Google Play": "from the App Store or Google Play",
    },
    franchise: {
      Charge: "Charge",
      "with us!": "with us!",
      "Become a part": "Become a part",
      "of the sharing services business": "of the sharing services business",
      "Make order": "Make order",
      "Who are we and how does it all work?":
        "Who are we and how does it all work?",
      "Service of short-term rent": "Service of short-term rent",
      "of powerbanks for gadgets": "of powerbanks for gadgets",
      "In our network": "In our network",
      "there are 1000 terminals": "there are 1000 terminals",
      "in Moscow": "in Moscow",
      including: "including",
      "100 terminals": "100 terminals",
      "inside the metro.": "inside the subway.",
      "The fully automated process of finding":
        "The fully automated process of finding",
      "the nearest place to rent a powerbank, payment":
        "the nearest place to rent a powerbank, payment",
      "and refund.": "and refund.",
      "What do we offer?": "What do we offer?",
      "Stations with chargers": "Stations with chargers",
      "4, 8, 24, 48 slots": "4, 8, 24, 48 slots",
      "Technical support": "Technical support",
      "We take over most of the work, you only need to arrange the stations":
        "We take over most of the work, you only need to arrange the stations",
      Software: "Software",
      "Partner's personal account": "Partner's personal account",
      "You will be able to see the service usage statistics":
        "You will be able to see the service usage statistics",
      "Franchisee's Guide": "Franchisee's Guide",
      "Instructions for setting up equipment, recommendations for placement locations":
        "Instructions for setting up equipment, recommendations for placement locations",
      "Terminal formats": "Terminal formats",
      "1000+ terminals throughout Moscow": "1000+ terminals throughout Moscow",
      "8 slots": "8 slots",
      "4 slots": "4 slots",
      "24 slots": "24 slots",
      "48 slots": "48 slots",
      Size: "Size",
      "Terminal for": "Terminal for",
      "Compact, ideal for:": "Compact, ideal for:",
      "The station is completely autonomous, only the presence of an outlet is required.":
        "The station is completely autonomous, only the presence of an outlet is required.",
      "cafes, bars, restaurants": "cafes, bars, restaurants",
      "beauty salons": "beauty salons",
      "other establishments": "other establishments",
      "Send a request": "Send a request",
      "coffee shops": "coffee shops",
      barbershops: "barbershops",
      markets: "markets",
      universities: "universities",
      polyclinics: "polyclinics",
      "shopping centers": "shopping centers",
      airports: "airports",
      "train stations": "train stations",
      "Franchise conditions": "Franchise conditions",
      "Return on investment from 9 months":
        "Return on investment from 9 months",
      "Investments from 450,000 rubles": "Investments from 450,000 ₽",
      "Royalties 20%": "Royalties 20%",
      "Lump sum payment of 0 rubles": "Lump sum payment of 0 rubles",
      "Revenue from the installation": "Revenue from the installation",
      Calculator: "Calculator",
      "Select the terminal type": "Select the terminal type",
      "Enter the quantity": "Enter the quantity",
      "Your investment": "Your investment",
      "Revenue in 12 months": "Monthly revenue in one year",
      "Get an estimate": "Get an estimate",
      "Left a request": "Left a request",
      "What's next?": "What's next?",
      hours: "hours",
      "the time for which": "the time for which",
      "we will contact you": "we will contact you",
      call: "call",
      "we will answer all your questions and give you a personal offer":
        "we will answer all your questions and give you a personal offer",
      days: "days",
      "the deadline for the conclusion": "the deadline for the conclusion",
      "of the contract": "of the contract",
      "Let's charge people together!": "Let's charge people together!",
      "Send a request and get an offer for your city":
        "Send a request and get an offer for your city",
      "Phone number": "Phone number",
      "First Last Name": "First Last Name",
      Email: "Email",
      City: "City",
      "By clicking the button you agree to our":
        "By clicking the button you agree to our",
      "privacy policy": "privacy policy",
      "Charge on the go!": "Charge on the go!",
      "Any other questions? Write to us!": "Any other questions? Write to us!",
      "We at EnerGO are all charged up!": "We at EnerGO are all charged up!",
      "Need a charging zone": "Need a charging zone",
      "for an event?": "for an event?",
      "Do you want to install our": "Do you want to install our",
      "terminal in your establishment?": "terminal in your establishment?",
      "EnerGo franchise": "EnerGo franchise",
      "Why choose us?": "Why choose us?",
      "Just manage. The key task is to install a charging station in a passageway and provide a second line of technical support.": "Just manage. The key task is to install a charging station in a passageway and provide a second line of technical support.",
      "The market is growing. Interest in the service is increasing every day.": "The market is growing. Interest in the service is increasing every day.",
      "No lump-sum payment. You only pay for the equipment.": "No lump-sum payment. You only pay for the equipment.",
      "The opportunity to purchase a Master franchise. Manage a city, region or country!": "The opportunity to purchase a Master franchise. Manage a city, region or country!",
      "Quick profit - revenue from the first days of installation.": "Quick profit - revenue from the first days of installation."
    },
  },
};

export default en;
