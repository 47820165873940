import { useTranslation } from "react-i18next";
import cn from "classnames";

export default function Footer() {
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <footer class="footer-v3 padding-y-lg bg-primary">
        <div class="footer-v3__container container max-width-adaptive-lg grid items-start gap-sm">
          <div class="footer-v3__logo col-3@md">
            <a href="#0">
              <svg
                width={168}
                height={35}
                viewBox="0 0 168 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37.5519 0C45.5285 0 47.3693 6.15537 47.3693 8.98684V23.7597H41.4789V9.35616C41.4789 8.12508 41.1107 7.14022 40.4971 6.52469C39.8835 5.90915 38.9018 5.53983 37.9201 5.53983C36.6929 5.53983 35.7111 5.90915 34.9748 6.7709C34.2385 7.50955 33.8704 8.74062 33.8704 10.341V23.6366H27.98C27.98 23.6366 27.98 12.3107 27.98 8.49441C27.7345 4.06254 31.5388 0 37.5519 0Z"
                  fill="var(--color-black)"
                />
                <path
                  d="M91.5469 0.123047V6.52463C88.2335 6.52463 85.2883 7.14016 85.2883 12.3107V23.6365H79.3979C79.3979 23.6365 79.3979 14.4035 79.3979 9.10988C79.2752 1.84655 87.2518 0.123047 91.5469 0.123047Z"
                  fill="var(--color-black)"
                />
                <path
                  d="M141.861 20.8051C139.53 23.1442 136.584 24.3752 133.271 24.3752C129.835 24.3752 127.013 23.1442 124.681 20.8051C122.349 18.4661 121.122 15.6346 121.122 12.1876C121.122 8.74062 122.349 5.90915 124.681 3.57011C127.013 1.23107 129.835 0 133.271 0C136.707 0 139.53 1.23107 141.861 3.57011C144.193 5.90915 145.42 8.74062 145.42 12.1876C145.42 15.6346 144.316 18.4661 141.861 20.8051ZM128.853 16.7426C130.08 17.9737 131.553 18.5892 133.271 18.5892C134.989 18.5892 136.584 17.9737 137.689 16.7426C138.916 15.5115 139.53 14.0342 139.53 12.1876C139.53 10.341 138.916 8.86373 137.689 7.63265C136.462 6.40158 134.989 5.78604 133.271 5.78604C131.553 5.78604 130.08 6.40158 128.853 7.63265C127.626 8.86373 127.135 10.341 127.135 12.1876C127.135 14.0342 127.626 15.5115 128.853 16.7426Z"
                  fill="#E30613"
                />
                <path
                  d="M118.177 12.0645C118.177 8.61751 117.072 5.90915 114.618 3.57011C112.287 1.23107 109.341 0 106.028 0C102.592 0 99.7693 1.23107 97.4377 3.57011C95.1061 5.90915 93.8789 8.74062 93.8789 12.1876C93.8789 15.6346 95.1061 18.4661 97.4377 20.8051C99.7693 23.1442 102.592 24.3752 106.028 24.3752C108.36 24.3752 110.446 23.7597 112.409 22.6517V23.8828C112.409 25.7294 111.918 27.0836 110.814 28.0685C109.709 29.0533 108.237 29.5458 106.396 29.5458C104.678 29.5458 103.328 29.1764 102.346 28.4378C101.365 27.6991 100.874 26.7143 100.874 25.7294H94.9834C94.9834 28.5609 96.0878 30.7768 98.2967 32.5003C100.506 34.1007 103.205 34.9625 106.396 34.9625C109.587 34.9625 112.287 33.9776 114.618 32.131C116.95 30.2844 118.054 27.576 118.054 24.0059C118.054 23.7597 118.177 12.4338 118.177 12.0645ZM110.446 16.7426C109.219 17.9737 107.746 18.5892 106.028 18.5892C104.31 18.5892 102.837 17.9737 101.61 16.7426C100.383 15.5115 99.8921 14.0342 99.8921 12.1876C99.8921 10.341 100.506 8.86373 101.61 7.63265C102.837 6.40158 104.31 5.78604 106.028 5.78604C107.746 5.78604 109.341 6.40158 110.446 7.63265C111.673 8.86373 112.286 10.341 112.286 12.1876C112.286 14.0342 111.673 15.5115 110.446 16.7426Z"
                  fill="#E30613"
                />
                <path
                  d="M15.5851 17.6043C12.8853 19.9434 7.24032 18.5892 6.3813 14.5267H24.1753C24.7889 9.60237 23.1936 5.78604 20.9847 3.44701C18.7757 1.23107 15.9532 0 12.5172 0C8.83565 0 5.76771 1.10797 3.43608 3.44701C1.22717 5.78604 0 8.61751 0 12.0645C0 15.5115 1.10446 18.4661 3.43608 20.8051C5.76771 23.1442 8.83565 24.2521 12.5172 24.2521C15.8305 24.2521 20.3711 22.7749 23.0709 17.4812L15.5851 17.6043ZM6.25858 9.97169C6.62673 8.49441 8.09934 5.53983 12.2717 5.53983C15.8305 5.53983 17.9167 8.3713 18.1622 9.84859L6.25858 9.97169Z"
                  fill="var(--color-black)"
                />
                <path
                  d="M66.5128 17.6043C63.8131 19.9434 58.1681 18.5892 57.309 14.5267H75.103C75.7166 9.60237 74.1213 5.78604 71.9124 3.44701C69.8262 1.23107 67.0037 0 63.5676 0C59.8861 0 56.8182 1.10797 54.4865 3.44701C52.1549 5.78604 50.9277 8.61751 50.9277 12.0645C50.9277 15.5115 52.0322 18.4661 54.3638 20.8051C56.6954 23.1442 59.7634 24.2521 63.4449 24.2521C66.1447 24.2521 71.2988 22.7749 73.9986 17.4812L66.5128 17.6043ZM57.309 9.97169C57.6772 8.49441 59.0271 5.53983 63.1995 5.53983C66.7583 5.53983 68.8445 8.3713 69.0899 9.84859L57.309 9.97169Z"
                  fill="var(--color-black)"
                />
                <path
                  d="M165.423 6.77109H154.747C153.274 6.77109 152.17 5.66313 152.17 4.18584C152.17 2.70855 153.274 1.60059 154.747 1.60059H165.423C166.896 1.60059 168 2.70855 168 4.18584C168 5.54002 166.773 6.77109 165.423 6.77109Z"
                  fill="#E30613"
                />
                <path
                  d="M165.423 14.1568H154.747C153.274 14.1568 152.17 13.0489 152.17 11.5716C152.17 10.0943 153.274 8.98633 154.747 8.98633H165.423C166.896 8.98633 168 10.0943 168 11.5716C168 13.0489 166.773 14.1568 165.423 14.1568Z"
                  fill="#E30613"
                />
                <path
                  d="M165.423 21.6666H154.747C153.274 21.6666 152.17 20.5586 152.17 19.0813C152.17 17.6041 153.274 16.4961 154.747 16.4961H165.423C166.896 16.4961 168 17.6041 168 19.0813C168 20.4355 166.773 21.6666 165.423 21.6666Z"
                  fill="#E30613"
                />
              </svg>
            </a>
          </div>

          <nav class="footer-v3__nav col-4@md color-black">
            <ul class="footer-v3__nav-list">
              <li class="footer-v3__nav-item margin-bottom-sm">
                <a href="tel:88006004264">
                  {t("footer::Support service")}{" "}
                  <span class="flex inline-flex@md">8 (800) 600 42 64</span>
                </a>
              </li>
              <li class="footer-v3__nav-item">
                <a
                  href="#0"
                  onClick={() => changeLanguageHandler("ru")}
                  className={cn({ "font-bold": i18n.language === "ru" })}
                >
                  Русский
                </a>{" "}
                |{" "}
                <a
                  href="#0"
                  onClick={() => changeLanguageHandler("en")}
                  className={cn({ "font-bold": i18n.language === "en" })}
                >
                  English
                </a>
              </li>
            </ul>
          </nav>

          <div class="col-4@md color-black">
            {t("footer::ENERGO TECHNOLOGIES LLC")}
            <br />
            {t("footer::INN")} 5024194594 <br />
            <a target="blank" href="terms_of_use.pdf" class="color-black">
              {t("footer::User Agreement")}
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}
